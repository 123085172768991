import React, { Component } from 'react'
import './FeatureCard.scss'

class FeatureCard extends Component {

    render() {

        const { title, text, col } = this.props
        
        return (
            <div className={"feature-card-" + col} id="feature-card">
                <span className="card-title">{title}</span>
                <p>{text}</p>
            </div>
        )
    }
}

export default FeatureCard