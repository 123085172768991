import React from "react"
import './solutions.scss'
import FeatureCard from '../components/FeatureCard'
import selfiLogo from '../images/selfi-corp.svg'
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const PeopleAnalytics = () => {

    const data = useStaticQuery(graphql`
	query {
		selfi_screenshot: file(relativePath: { eq: "selfi-corp-screenshot.png" }) {
			childImageSharp {
				fluid(maxWidth: 1050, maxHeight: 649) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
	  }
	`)

    return (
        <div className="solutions">
            <SEO title="People Analytics for Companies" />
            <section className="bg--tertiary" id="people-analytics-web" style={{minHeight: '39.5rem'}}>
                <div className="container content solutions-top-container">
                    <div className="col-sm-8 col-md-8 screenshot-container">
                        <div className="selfi-screenshot" id="people-analytics">
                            <Image
                                fluid={data.selfi_screenshot.childImageSharp.fluid}
                                alt="People Analytics" />
                        </div>
                    </div>
                    <div className="col-sm-5 col-md-5">
                        <div className="row">
                            <span className="text-gray-light">OUR SOLUTIONS</span>    
                        </div>
                        <div className="row">
                            <img src={selfiLogo} className={"selfi-logo"} alt="" />
                        </div>
                        <div className="row">
                            <h1 className="solution-title">People Analytics for Companies</h1>
                            <p>A platform for companies to measure traits for role-fit, career development and recruitment. Companies can customize quizzes to test specific traits from our growing traits database, view result analysis, and find candidates of choice easily using trait-strength filters.</p>
                            <button className="secondary" onClick={() => window.open('https://selfi.ai/', '_blank')}>Visit website</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg--tertiary" id="people-analytics-web">
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section-title-secondary">Features</h1>
                        </div>
                    </div>
                    <div className="row features-container">
                        <FeatureCard col={4} title={"Select traits to test"} text={"Create custom quizzes that tests specific traits relevant to job role. No more redundant metrics to go through, only measure what is needed. Your candidates don't have to sit through unnecessarily long tests."} />
                        <FeatureCard col={4} title={"Supports your branding"} text={"Make a memorable impression on candidates. Brand your custom quizzes with your company logo, welcome messages and follow-up emails."} />
                        <FeatureCard col={4} title={"View personality trends"} text={"Do you understand your people? Use our visual tool to view the breakdown of personality across hiring candidates and existing employees."} />
                        <FeatureCard col={4} title={"Shortlisting made easy"} text={"Filter, compare and shortlist candidates that are most likely to excel in particular roles. Compare visually how one candidate compares to another. Already have good performers? See how similar candidates are relative to them for better role-fit."} />
                    </div>
                </div>
            </section>
            <div id="people-analytics-mobile">
                <section className="bg--tertiary" id="people-analytics">
                    <div className="container content solutions-top-container">
                        <div className="screenshot-container">
                            <div className="selfi-screenshot" id="people-analytics">
                                <Image
                                    fluid={data.selfi_screenshot.childImageSharp.fluid}
                                    alt="People Analytics" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <span className="text-gray-light">OUR SOLUTIONS</span>    
                            </div>
                            <div className="row">
                                <h1 className="solution-title">People Analytics for Companies</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="selfi-mobile-container" id="people-analytics">
                    <div className="container content">
                        <div className="selfi-mobile-card">
                            <div className="row">
                                <div className="col-12">
                                    <img src={selfiLogo} className={"selfi-logo"} alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p>A platform for companies to measure traits for role-fit, career development and recruitment. Companies can customize quizzes to test specific traits from our growing traits database, view result analysis, and find candidates of choice easily using trait-strength filters.</p>
                                    {/* <button className="secondary">Try it out — No registration required</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg--tertiary">
                    <div className="container content">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="section-title-secondary">Features</h1>
                            </div>
                        </div>
                        <div className="row features-container">
                            <FeatureCard col={4} title={"Select traits to test"} text={"Create custom quizzes that tests specific traits relevant to job role. No more redundant metrics to go through, only measure what is needed. Your candidates don't have to sit through unnecessarily long tests."} />
                            <FeatureCard col={4} title={"Supports your branding"} text={"Make a memorable impression on candidates. Brand your custom quizzes with your company logo, welcome messages and follow-up emails."} />
                            <FeatureCard col={4} title={"View personality trends"} text={"Do you understand your people? Use our visual tool to view the breakdown of personality across hiring candidates and existing employees."} />
                            <FeatureCard col={4} title={"Shortlisting made easy"} text={"Filter, compare and shortlist candidates that are most likely to excel in particular roles. Compare visually how one candidate compares to another. Already have good performers? See how similar candidates are relative to them for better role-fit."} />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PeopleAnalytics